import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Subject, BehaviorSubject } from 'rxjs';
import { CommonDataStrecture } from '@app/shared/model/commondatastrecture';
export type InternalStateType = {
  [key: string]: any
};
@Injectable({
  providedIn: 'root'
})
export class AppService {
  _state: InternalStateType = {};
  httpOptions = {
    headers: new HttpHeaders(),
    withCredentials: true
  };
  private _navigationNotify = new BehaviorSubject<string>('');
  readonly _navigationNotify$ = this._navigationNotify.asObservable();

  private contractSource = new BehaviorSubject<string>('');
  readonly contract$  = this.contractSource.asObservable();

  _tourData: any;
  constructor(private httpClient: HttpClient) { }

  setLocalStorageFunctions() {
    Storage.prototype.getEncryptedItem = function (item) {
      if (item) {
        let encodedKey = btoa(item);
        let itemValue = localStorage.getItem(encodedKey);
        if (itemValue) {
          return atob(itemValue);
        }
      }
      return null;
    }
    Storage.prototype.setEncryptedItem = function (item, itemValue) {
      if (item && itemValue) {
        let encodedKey = btoa(item);
        let encodedValue = btoa(itemValue);
        localStorage.setItem(encodedKey, encodedValue);
        return true;
      }
      return false;
    }
    Storage.prototype.removeEncryptedItem = function (item) {
      if (item) {
        let encodedKey = btoa(item);
        localStorage.removeItem(encodedKey);
        return true;
      }
      return false;
    }
  }

  createAccessToken(){
    return  this.httpClient.post(environment.API_END_POINT+'gate2bAPI/refreshTokenCreate',{}).pipe(map((response:Response)=>response))
  }

  createioAccessToken(){
    return  this.httpClient.post(environment.API_END_POINT+'gate2bAPI/createAccessToken',{}).pipe(map((response:Response)=>response))
  }

  bexioLogout(data) {
    return this.httpClient.post(environment.API_END_POINT+`gate2bAPI/logout`,data, this.httpOptions);
  }
  
  notify(data) {
    this._navigationNotify.next(data);
  }
  setTourData(data){
    this._tourData = data;
  }
  getTourData(){
    return this._tourData;
  }
  downloadDocument(data){
    return this.httpClient.post(environment.API_GATE2B_END_POINT+"gate2bAPI/setContractPdf",data).pipe(map((responseData: CommonDataStrecture)=>responseData));

  }
  saveContractDetails(data){
    return this.httpClient.post(environment.API_GATE2B_END_POINT+"gate2bAPI/signContractPdf",data).pipe(map((responseData: CommonDataStrecture)=>responseData));
  }
  existContract(data){
    return this.httpClient.post(environment.API_GATE2B_END_POINT+"gate2bAPI/existContractDetails",data).pipe(map((responseData: CommonDataStrecture)=>responseData));
  }
  setContract(contract):void {
    this.contractSource.next(contract);
  }
  contractRemindLater(data){
    return this.httpClient.post(environment.API_GATE2B_END_POINT + 'gate2bAPI/sendContractMail', data);

  }
}
