import { Component, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { CommonService } from './core/services/common.service';
import { environment } from '@env/environment';
import { TutorialEventsService } from './core/services/tutorial-events.service';
import { NavigationService } from './shared/layout/navigation/navigation.service';
import { EmitService } from './ts/services/emit.service';
import { VersionCheckService } from "@app/shared/versioncheck/version-check.service";
import { Subscription } from 'rxjs';
declare var $:any
export let browserRefresh = false;
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet> <div class="cookie-consent hide-consent show-consent" *ngIf="showCookieConsent"><span class="cookie-text">{{'common_login_cookie_content' |i18n}}<a [href]="accountsUrl+'privacy-policy'" target="_blank">{{'common_all_privacy_policy' | i18n}} </a></span><button class="consent-btn mt-4" (click)="hideCookieConsent()"> {{'footer_cookie_gotIt' | i18n}}</button></div>`,
  // host: {
  //   '(body:dblclick)': 'ondblClick($event)',
  //   '(window:keyup)': 'onKeyPress($event)'
  // }
})
export class AppComponent implements OnInit{
  currentUrl;
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  action = '';
  inputOpen: boolean;
  isFirstTime: boolean = false;
  stepContent: any;
  ebillTour: {};
  showCookieConsent:boolean = true;
  accountsUrl = environment.ACCOUNTS_URL;
  subscription: Subscription;
  constructor(private appService : AppService, private commonService : CommonService,private router: Router,private idle: Idle,
    private navigationService:NavigationService,private ts: TutorialEventsService,private emitService: EmitService,private versionCheckService:VersionCheckService){
      this.subscription = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh = !router.navigated;
        }
    });
    this.appService.setLocalStorageFunctions();
// set idle parameters
this.idle.setIdle(1800); // how long can they be inactive before considered idle, in seconds
this.idle.setTimeout(10); // how long can they be idle before considered timed out, in seconds
this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

// do something when the user becomes idle
this.idle.onIdleStart.subscribe(() => {
  this.idleState = "IDLE";
});
// do something when the user is no longer idle
this.idle.onIdleEnd.subscribe(() => {
  this.idleState = "NOT_IDLE";
  this.countdown = null;
  // cd.detectChanges(); // how do i avoid this kludge?
});
// do something when the user has timed out
this.idle.onTimeout.subscribe(() => {
  localStorage.clear();
  this.idleState = "TIMED_OUT";
  this.appService.bexioLogout('').subscribe(data=>{
    if(data['status'])
      window.location.href = window.origin+'/#/login/logout';
  })
});
// do something as the timeout countdown does its thing
this.idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);
  let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.checkCookie)));  
	  if (cookieExist) {
	    cookieExist =  JSON.parse(cookieExist);
      console.log(cookieExist['partyID'])
	    localStorage.getEncryptedItem('partyID')!=cookieExist['partyID'] ? localStorage.removeEncryptedItem('userData') : '';
	    localStorage.setEncryptedItem('userMail',cookieExist['userMail']);
	    localStorage.setEncryptedItem('partyID',cookieExist['partyID']);
	    localStorage.setEncryptedItem('userID',cookieExist['userID']);
	    localStorage.setEncryptedItem('accId',cookieExist['accId']);
	    localStorage.setEncryptedItem('netcomId',cookieExist['netcomId']);
      localStorage.setEncryptedItem('isBexioCompany',cookieExist.hasOwnProperty('isBexioCompany')?cookieExist['isBexioCompany']:'false');
    
	  }
    // let p = String([
    //   "bexio.dashboard.*",
    //   "bexio.sales.*",
    //   "bexio.sales.list",
    //   "bexio.sales.push",
    //   "bexio.purchases.*",
    //   "bexio.purchases.list",
    //   "bexio.purchases.push",
    //   "bexio.connections.*",
    //   "bexio.connections.list",
    //   "bexio.connections.create",
    //   "bexio.contacts.*",
    //   "bexio.contacts.list",
    //   "bexio.contacts.invite",
    // ]);

    // let a = this.commonService.encrypt(p);
    // localStorage.setEncryptedItem('permissions',a); 
  }
  ngOnInit(): void {  
    this.cookieConsent();
    // this.reset();
    // setTimeout(() => {
    //   // let language = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].language: "de";
    //   // this.emitService.emitLoginLanguageChange(language);
    // },100);
    // let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.checkCookie)));
    // if (localStorage.getEncryptedItem('isBexioCompany')=='true') {
      // this.getTutorialData();
      this.ts.cast.subscribe(user=> {
        if(user && localStorage.getEncryptedItem('isBexioCompany')=='true'){
          this.getTutorialData();
        }
      });
    // }
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl=e.url;
      }
    });
    setInterval(() => {
      if(this.currentUrl != "/" && this.currentUrl != "/login" && this.currentUrl != "/landing")
      return forkJoin([this.appService.createAccessToken(),this.appService.createioAccessToken()]
        ).subscribe(res =>{
      });
    },600000);
    // this.reset();
    this.versionCheckService.initVersionCheck('version.json');
    this.emitService.emitPreviosUrl(window.location.href)
  }
  
  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }
  getTutorialData(){
    if( Object.keys(this.ebillTour = {}).length > 0 ){ return;}
    let formData = new FormData();
    let lanId = this.commonService.getCurrentLanguageCode();
    lanId = lanId ? lanId : 'en';
    formData.append('language',lanId.toUpperCase());
    formData.append('type','tour');
    formData.append('fk_application_version',environment.APPLICATION_VERSION);
    formData.append("partyId", localStorage.getEncryptedItem('partyID'));
    formData.append("accId", localStorage.getEncryptedItem('accId'));
    formData.append('roleAccId',localStorage.getEncryptedItem('accId'));
    formData.append('rolePartyId',localStorage.getEncryptedItem('partyID'));
    let object = this.commonService.createObject(formData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append('encrToken',encryptedToken);
    this.navigationService.getTourListData(encrData).subscribe( data => {
      // this.navigationService.setTourData(data["data"]);
      // // localStorage.setEncryptedItem('stepContent',JSON.stringify(data["data"]));    
      // localStorage.setEncryptedItem('stepContent',btoa(unescape(encodeURIComponent(JSON.stringify(data["data"])))));
    
      this.stepContent =  data["data"];
      this.ebillTour = {};
      this.ebillTour = this.stepContent["tourData"];
      // Object.keys(this.stepContent['stepData']).forEach((el) => {
      //   this.ebillTour[this.stepContent['stepData'][el].appmodule] = {
      //     stepData: {},
      //     steps: [],
      //   };
      // });
      // Object.keys(this.stepContent['stepData']).forEach((el) => {       
      //   let tourModule = this.stepContent['stepData'][el].appmodule;
      //   var obj = {};
      //   obj[el] = this.stepContent['stepData'][el];
      //   Object.assign(this.ebillTour[tourModule]['stepData'],obj);
      //   this.ebillTour[tourModule]['steps'].push(
      //     this.stepContent['stepData'][el].path
      //   );
      // });
      localStorage.setEncryptedItem('stepContent',btoa(unescape(encodeURIComponent(JSON.stringify(this.ebillTour)))));
      this.stepContent = this.ebillTour;
      this.appService.setTourData(this.stepContent);
      this.navigationService.setTourData(this.stepContent)
      this.ts.chageData(true);
      this.isFirstTime = true;
    })
  }

  // ondblClick(e) {
  //   var target = $(e.target);
  //   if (target.length > 0 && target[0].className == 'editInput') {
  //     let textConteny =  $("span.editInput").text();
  //     $("textarea.in").val(textConteny)
  //     $('.editInput').css('display','none');
  //     $("textarea.in").css('display','block');
  //     $('.btn-save').css('display','block');
  //   }
  //   else if( target.length > 0 && target[0].className == 'joyride-step__title'){
  //     let titleText = $("div.joyride-step__title").text();
  //     if($('.tour-title-input').length == 0){
  //       $('.joyride-step__title').parent().append('<input type="text" class="form-control tour-title-input input-field" />');
  //     }
  //     else{
  //       $('.tour-title-input').css('display','block')
  //     }
      
  //     $('.tour-title-input').val(titleText);
  //     $('.btn-save').css('display','block');
  //     $('.joyride-step__title').css('display','none');
  //   }
  // }
  // onKeyPress(e){
  //   if( e.keyCode == 13){
  //     if(!$("textarea.in").is(":visible") && !$("input.tour-title-input").is(":visible") ){
  //       $('.joyride-step__next-container button').click();
  //     }
  //   }
  // }

  saveContent(){
    let stepId = $("textarea.in").attr("id");
    let saveContent = this.stepContent.stepData[stepId];
    let desc,title;
    if( $("input.tour-title-input").is(":visible") ){
      if($("input.tour-title-input").val())
        title = $("input.tour-title-input").val();
      else
        return;
    }
    else{
      title = saveContent.title
    }
    if($("textarea.in").is(":visible")){
      if( $("textarea.in").val() )
        desc = $("textarea.in").val();
      else
        return;
    }
    else{
      desc = saveContent['description'];
    }
    if($("textarea.in").is(":visible") || $("input.tour-title-input").is(":visible")){
        this.inputOpen = false;
        let a = $("textarea.in").val();
        let b = $("input.tour-title-input").val();
        // if( a != "" || b != ""){
          let formData = new FormData();
          let lanId = this.commonService.getCurrentLanguageCode();
          lanId = lanId ? lanId : 'en';
          formData.append('language',lanId.toUpperCase());
          formData.append('id',saveContent.cid);
          formData.append('title',title ? title : saveContent.title);
          formData.append('description',desc ? desc : saveContent['description']);
          formData.append('type','tour');
          formData.append('roleAccId',localStorage.getEncryptedItem('accId'));
          formData.append('rolePartyId',localStorage.getEncryptedItem('partyID'));
          let object = this.commonService.createObject(formData);
          let token = this.commonService.createToken(object);
          let encryptedToken = this.commonService.encrypt(token);
          let encrData = new FormData();
          encrData.append('encrToken',encryptedToken);
          this.navigationService.updateTour(encrData).subscribe( data => {
            this.ts.editUser(true);
            if( title){
              $('.joyride-step__title').text(title);
              $('.joyride-step__title').css('display','block');
              $('.tour-title-input').css('display','none')
            }
            if( desc){
              $("span.editInput").text(desc);
              $('.editInput').css('display','block');
              $("textarea.in").css('display','none');
              
            }
            $('.btn-save').css('display','none');

          });
        // }
        
      }
  }
  cookieConsent(){
    if( this.commonService.getCookie(environment.cookieConsent) == 'false' ){
      this.showCookieConsent = false;
    }else{
      this.showCookieConsent = true;
      this.commonService.setCookie(environment.cookieConsent,"true",10000,environment.cookieDomain,'/');
    }
  }
  hideCookieConsent(delay =0){
    if(this.showCookieConsent){
      setTimeout(()=>{
      $(".cookie-consent").fadeOut(1500); 
      this.showCookieConsent = false ;
      // localStorage.setEncryptedItem("cookieConsent","false");   
      this.commonService.setCookie(environment.cookieConsent,"false",10000,environment.cookieDomain,'/');
      }, delay)
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
